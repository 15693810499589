<template>
  <div :class="$style.chat__inputWrapper">
    <input
      :class="$style.chat__input"
      :value="value"
      type="text"
      placeholder="Сообщение"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="$emit('enter', $event.target.value)"
    />
    <!-- <input
      id="inputFile"
      ref="image"
      type="file"
      style="display: none"
      multiple
      accept="image/jpg, image/jpeg, image/png"
      @change="getBase64($event)"
    />
    <label :class="$style.chat__label" for="inputFile">
      <img src="@/assets/icons/pin.svg" alt="pin" />
    </label> -->
  </div>
</template>

<script>
export default {
  name: 'ChatInput',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    getBase64(event) {
      if (event.target.files[0]) {
        for (const file of event.target.files) {
          const formData = new FormData()
          formData.append('files', file)

          const reader = new FileReader()

          reader.onload = (e) => {
            this.$store.commit('pushChatImage', { src: e.target.result, formData })
          }

          reader.readAsDataURL(file)
        }
      } else {
        this.$refs.image.value = null
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.chat {
  &__inputWrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 2.75rem;
    border: 1px solid $gray;
    border-radius: 1rem;
  }

  &__input {
    width: 100%;
    padding: 1.25rem 0;
    font-size: 1.25rem;
  }

  &__label {
    display: flex;
    align-items: center;
    z-index: 10;
    cursor: pointer;
  }
}
</style>
